import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { NameService } from './name.service';
import { Filters } from './names';
import { DEFAULT_SETTINGS, Settings } from './settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private data$ = new BehaviorSubject<Settings>(this.load());

  public filters$ = this.data$.pipe(
    map(d => d.filters),
    distinctUntilChanged()
  );

  public savedFilters$ = this.data$.pipe(
    map(d => d.savedFilters),
    distinctUntilChanged()
  );

  // public get data() {
  //   return this.data$.value;
  // }

  // private get filters() {
  //   return this.data$.value.filters;
  // }

  public set filters(filters: Filters) {
    const data = this.data$.value;
    data.filters = filters;
    this.data$.next(data);
  }

  // public get savedFilters() {
  //   return this.data$.value.savedFilters;
  // }

  constructor(private readonly nameService: NameService) {
    this.data$.subscribe(() => this.save());
    this.filters$.subscribe(filters => this.nameService.filter(filters));
  }

  public hasStarting(item: string) {
    return this.hasFilter('starting', item);
  }

  public addStarting(item: string) {
    this.addFilter('starting', item);
  }

  public removeStarting(item: string) {
    this.removeFilter('starting', item);
  }

  public hasNotStarting(item: string) {
    return this.hasFilter('notStarting', item);
  }

  public addNotStarting(item: string) {
    this.addFilter('notStarting', item);
  }

  public removeNotStarting(item: string) {
    this.removeFilter('notStarting', item);
  }

  public hasIncluding(item: string) {
    return this.hasFilter('including', item);
  }

  public addIncluding(item: string) {
    this.addFilter('including', item);
  }

  public removeIncluding(item: string) {
    this.removeFilter('including', item);
  }

  public hasNotIncluding(item: string) {
    return this.hasFilter('notIncluding', item);
  }

  public addNotIncluding(item: string) {
    this.addFilter('notIncluding', item);
  }

  public removeNotIncluding(item: string) {
    this.removeFilter('notIncluding', item);
  }

  public hasEnding(item: string) {
    return this.hasFilter('ending', item);
  }

  public addEnding(item: string) {
    this.addFilter('ending', item);
  }

  public removeEnding(item: string) {
    this.removeFilter('ending', item);
  }

  public hasNotEnding(item: string) {
    return this.hasFilter('notEnding', item);
  }

  public addNotEnding(item: string) {
    this.addFilter('notEnding', item);
  }

  public removeNotEnding(item: string) {
    this.removeFilter('notEnding', item);
  }

  // public hasSavedFilter(name: string) {
  //   return this.data$.value.savedFilters.find(f => f.name === name) !== undefined;
  // }

  public addSavedFilters(name: string, filters: Filters) {
    const data = this.data$.value;
    data.savedFilters = data.savedFilters.filter(f => f.name !== name);
    data.savedFilters.push({ name, filters });
    this.data$.next(data);
  }

  public removeSavedFilters(name: string) {
    const data = this.data$.value;
    data.savedFilters = data.savedFilters.filter(f => f.name !== name);
    this.data$.next(data);
  }

  private load() {
    const stored = localStorage.getItem('settings');
    return stored ? JSON.parse(stored) : DEFAULT_SETTINGS;
  }

  private save() {
    localStorage.setItem('settings', JSON.stringify(this.data$.value));
  }

  private hasFilter(filter: string, item: string) {
    return this.data$.value.filters[filter].includes(item);
  }

  private addFilter(filter: string, item: string) {
    if (!item || this.hasFilter(filter, item)) {
      return;
    }

    const data = this.data$.value;
    const next = {
      ...data,
      filters: {
        ...data.filters,
        [filter]: [...data.filters[filter], item]
      }
    };
    this.data$.next(next);
  }

  private removeFilter(filter: string, item: string) {
    if (!this.hasFilter(filter, item)) {
      return;
    }

    const data = this.data$.value;
    const next = {
      ...data,
      filters: {
        ...data.filters,
        [filter]: data.filters[filter].filter(i => i !== item)
      }
    };
    this.data$.next(next);
  }
}
