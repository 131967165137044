import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Filters } from '../names';
import { SavedFilters } from '../settings';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent {
  public filters$ = this.settings.filters$;

  public savedFilters$ = this.settings.savedFilters$;

  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private readonly settings: SettingsService) {}

  public addStarting(event: MatChipInputEvent) {
    this.settings.addStarting(event.value);
    event.input.value = '';
  }

  public removeStarting(item: string) {
    this.settings.removeStarting(item);
  }

  public addNotStarting(event: MatChipInputEvent) {
    this.settings.addNotStarting(event.value);
    event.input.value = '';
  }

  public removeNotStarting(item: string) {
    this.settings.removeNotStarting(item);
  }

  public addIncluding(event: MatChipInputEvent) {
    this.settings.addIncluding(event.value);
    event.input.value = '';
  }

  public removeIncluding(item: string) {
    this.settings.removeIncluding(item);
  }

  public addNotIncluding(event: MatChipInputEvent) {
    this.settings.addNotIncluding(event.value);
    event.input.value = '';
  }

  public removeNotIncluding(item: string) {
    this.settings.removeNotIncluding(item);
  }

  public addEnding(event: MatChipInputEvent) {
    this.settings.addEnding(event.value);
    event.input.value = '';
  }

  public removeEnding(item: string) {
    this.settings.removeEnding(item);
  }

  public addNotEnding(event: MatChipInputEvent) {
    this.settings.addNotEnding(event.value);
    event.input.value = '';
  }

  public removeNotEnding(item: string) {
    this.settings.removeNotEnding(item);
  }

  public loadFilters(filters: SavedFilters) {
    this.settings.filters = filters.filters;
  }

  public addSavedFilters(name: string, filters: Filters) {
    this.settings.addSavedFilters(name, filters);
  }

  public removeSavedFilters(filters: SavedFilters) {
    this.settings.removeSavedFilters(filters.name);
  }
}
