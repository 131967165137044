import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { Filters } from './names';

@Injectable({
  providedIn: 'root'
})
export class NameService {
  public starting$ = new BehaviorSubject<string[]>([]);
  public notStarting$ = new BehaviorSubject<string[]>([]);
  public including$ = new BehaviorSubject<string[]>([]);
  public notIncluding$ = new BehaviorSubject<string[]>([]);
  public ending$ = new BehaviorSubject<string[]>([]);
  public notEnding$ = new BehaviorSubject<string[]>([]);
  public accepted$ = new BehaviorSubject<string[]>(this.load('accepted'));
  public rejected$ = new BehaviorSubject<string[]>(this.load('rejected'));

  public all$ = this.fetch();

  public filtered$ = combineLatest([
    this.all$,
    this.starting$,
    this.notStarting$,
    this.including$,
    this.notIncluding$,
    this.ending$,
    this.notEnding$,
    this.accepted$,
    this.rejected$
  ]).pipe(
    map(([names, starting, notStarting, including, notIncluding, ending, notEnding, accepted, rejected]) => {
      return names
        .filter(this.starting(starting))
        .filter(this.notStarting(notStarting))
        .filter(this.including(including))
        .filter(this.notIncluding(notIncluding))
        .filter(this.ending(ending))
        .filter(this.notEnding(notEnding))
        .filter(this.notIn(accepted))
        .filter(this.notIn(rejected));
    })
  );

  constructor(private readonly http: HttpClient) {}

  public filter(filters: Filters) {
    this.starting$.next(filters.starting || []);
    this.notStarting$.next(filters.notStarting || []);
    this.including$.next(filters.including || []);
    this.notIncluding$.next(filters.notIncluding || []);
    this.ending$.next(filters.ending || []);
    this.notEnding$.next(filters.notEnding || []);
  }

  public accept(name: string) {
    const accepted = [...this.accepted$.value, name];
    this.accepted$.next(accepted);
    localStorage.setItem('accepted', JSON.stringify(accepted));
  }

  public reject(name: string) {
    const rejected = [...this.rejected$.value, name];
    this.rejected$.next(rejected);
    localStorage.setItem('rejected', JSON.stringify(rejected));
  }

  public unaccept(name: string) {
    const accepted = this.accepted$.value.filter(n => n !== name);
    this.accepted$.next(accepted);
    localStorage.setItem('accepted', JSON.stringify(accepted));
  }

  public unreject(name: string) {
    const rejected = this.rejected$.value.filter(n => n !== name);
    this.rejected$.next(rejected);
    localStorage.setItem('rejected', JSON.stringify(rejected));
  }

  private fetch() {
    return this.http.get('/assets/names.txt', { responseType: 'text' }).pipe(
      map(content =>
        content
          .split('\n')
          .map(n => n.trim())
          .filter(Boolean)
      ),
      share()
    );
  }

  private starting(values: string[]) {
    if (values.length === 0) {
      return () => true;
    }
    return (name: string) => values.find(s => name.startsWith(s));
  }

  private notStarting(values: string[]) {
    if (values.length === 0) {
      return () => true;
    }
    return (name: string) => !values.find(s => name.startsWith(s));
  }

  private including(values: string[]) {
    if (values.length === 0) {
      return () => true;
    }
    return (name: string) => values.find(i => name.includes(i));
  }

  private notIncluding(values: string[]) {
    if (values.length === 0) {
      return () => true;
    }
    return (name: string) => !values.find(i => name.includes(i));
  }

  private ending(values: string[]) {
    if (values.length === 0) {
      return () => true;
    }
    return (name: string) => values.find(i => name.endsWith(i));
  }

  private notEnding(values: string[]) {
    if (values.length === 0) {
      return () => true;
    }
    return (name: string) => !values.find(i => name.endsWith(i));
  }

  private notIn(values: string[]) {
    if (values.length === 0) {
      return () => true;
    }
    return (name: string) => !values.find(e => name === e);
  }

  private load(key: string) {
    const stored = localStorage.getItem(key);
    return stored ? JSON.parse(stored) : [];
  }
}
