import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NameService } from '../name.service';
import { formatName } from '../names';

@Component({
  selector: 'app-rejected',
  templateUrl: './rejected.component.html',
  styleUrls: ['./rejected.component.scss']
})
export class RejectedComponent {
  public size$ = new BehaviorSubject(25);
  public index$ = new BehaviorSubject(0);
  public count$ = this.count(this.names.rejected$);
  public items$ = this.paginate(this.names.rejected$, this.index$, this.size$);

  constructor(private readonly names: NameService) {}

  public unreject(name: string) {
    this.names.unreject(name.toLowerCase());
  }

  public reject(name: string) {
    this.names.reject(name.toLowerCase());
  }

  public onPageChanged(event: PageEvent) {
    this.index$.next(event.pageIndex);
  }

  private count(items$: Observable<string[]>) {
    return items$.pipe(map(items => items.length));
  }

  private paginate(items$: Observable<string[]>, index$: Observable<number>, size$: Observable<number>): Observable<string[]> {
    return combineLatest([items$, index$, size$]).pipe(
      map(([items, index, size]) => items.reverse().slice(index * size, (index + 1) * size)),
      map(names => names.map(formatName))
    );
  }
}
