export interface Filters {
  starting?: string[];
  notStarting?: string[];
  including?: string[];
  notIncluding?: string[];
  ending?: string[];
  notEnding?: string[];
}

export function formatName(name: string) {
  return name
    .split('-')
    .map(p => p[0].toUpperCase() + p.slice(1))
    .join('-');
}
