import { Component } from '@angular/core';
import { filter, map, startWith } from 'rxjs/operators';
import { NameService } from '../name.service';
import { formatName } from '../names';

@Component({
  selector: 'app-random',
  templateUrl: './random.component.html',
  styleUrls: ['./random.component.scss']
})
export class RandomComponent {
  public names$ = this.names.filtered$;

  public name$ = this.names$.pipe(
    startWith(['...']),
    filter(names => names.length > 0),
    map(names => names[Math.floor(Math.random() * names.length)]),
    map(formatName)
  );

  constructor(private readonly names: NameService) {}

  public accept(name: string) {
    this.names.accept(name.toLowerCase());
  }

  public reject(name: string) {
    this.names.reject(name.toLowerCase());
  }
}
