import { Filters } from './names';

export interface SavedFilters {
  name: string;
  filters: Filters;
}

export interface Settings {
  filters: Filters;
  savedFilters: SavedFilters[];
}

const DEFAULT_FILTERS: Filters = {
  starting: [],

  notStarting: [],

  including: [],

  notIncluding: [
    // 'at',
    // 'au', // sauf automne
    'cr',
    'dy',
    // 'er',
    'chr',
    // 'f',
    // 'it',
    // 'k', // sauf akiko
    // 'ol', // sauf coline
    // 'or',
    'out',
    'oy',
    // 'ph',
    'q'
    // 'ul',
    // 'ur' // sauf aurélie
  ],

  ending: [],

  notEnding: [
    'a',
    'ab',
    'ah',
    'aine',
    'al',
    'ale',
    // 'am',
    // 'ame',
    // 'ane',
    // 'anne',
    'ar',
    // 'cie',
    'cy',
    // 'de',
    // 'ée',
    'eb',
    'ed',
    'eine',
    'en',
    'enn',
    'enne',
    'er',
    'erte',
    'erthe',
    'et',
    'eth',
    // 'ette',
    'ez',
    'eze',
    'ène',
    'ic',
    // 'ice',
    'ick',
    // 'id',
    'ik',
    'is',
    'iss',
    // 'isse',
    'ïs',
    // 'ïse',
    'o',
    'ole',
    'on',
    'onde',
    'one',
    'onn',
    // 'onne',
    'ou',
    'ous',
    'ouss',
    'ousse',
    'ouz',
    'ouze',
    // 'rie',
    'ry'
    // 'u',
    // 'ur'
  ]
};

export const DEFAULT_SETTINGS: Settings = {
  filters: DEFAULT_FILTERS,
  savedFilters: [
    {
      name: 'Filtres par défaut',
      filters: DEFAULT_FILTERS
    }
  ]
};
